import bindAll from 'lodash.bindall'; 
import React from 'react';
import { defineMessages, injectIntl, FormattedMessage} from 'react-intl';
import ReactModal from 'react-modal';
import Box from '../box/box.jsx';
import styles from './telemetry-modal.css';
import axios from 'axios';
import config from '../../containers/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'; 
import SBFileUploaderHOC from '../../lib/sb-file-uploader-hoc.jsx'; // Certifique-se de que o caminho está correto

const messages = defineMessages({
    label: {
        id: 'gui.telemetryOptIn.label',
        defaultMessage: 'Report statistics to improve OpenBlock',
        description: 'OpenBlock telemetry modal label - for accessibility'
    },
    bodyText1: {
        defaultMessage: 'The OpenBlock Team is always looking to better understand how OpenBlock is used around the ' +
            'world. To help support this effort, you can allow OpenBlock to automatically send usage information to ' +
            'the OpenBlock Team.',
        description: 'First paragraph of body text for telemetry opt-in modal',
        id: 'gui.telemetryOptIn.body1'
    },
    bodyText2: {
        defaultMessage: 'The information we collect includes language selection, blocks usage, and some events like ' +
            'saving, loading, and uploading a project. We DO NOT collect any personal information. Please see our ' +
            '{privacyPolicyLink} for more information.',
        description: 'First paragraph of body text for telemetry opt-in modal',
        id: 'gui.telemetryOptIn.body2'
    },
    privacyPolicyLink: {
        defaultMessage: 'Privacy Policy',
        description: 'Link to the OpenBlock privacy policy',
        id: 'gui.telemetryOptIn.privacyPolicyLink'
    },
    optInText: {
        defaultMessage: 'Share my usage data with the Openblock Team',
        description: 'Text for telemetry modal opt-in button',
        id: 'gui.telemetryOptIn.optInText'
    },
    optInTooltip: {
        defaultMessage: 'Enable telemetry',
        description: 'Tooltip for telemetry modal opt-in button',
        id: 'gui.telemetryOptIn.optInTooltip'
    },
    optOutText: {
        defaultMessage: 'Do not share my usage data with the Openblock Team',
        description: 'Text for telemetry modal opt-in button',
        id: 'gui.telemetryOptIn.optOutText'
    },
    optOutTooltip: {
        defaultMessage: 'Disable telemetry',
        description: 'Tooltip for telemetry modal opt-out button',
        id: 'gui.telemetryOptIn.optOutTooltip'
    },
    settingWasUpdated: {
        defaultMessage: 'Your setting was updated.',
        description: 'Message indicating that the telemetry setting was updated and saved',
        id: 'gui.telemetryOptIn.settingWasUpdated'
    },
    closeButton: {
        defaultMessage: 'Close',
        description: 'Text for the button which closes the telemetry modal dialog',
        id: 'gui.telemetryOptIn.buttonClose'
    }
});

// This should be at least as long as the CSS transition
const SETTING_WAS_UPDATED_DURATION_MS = 3000;

class FileModal extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleCancel',
            'handleOptInOutChanged'
        ]);
        this.state = {
            // if the settingWasUpdated message is displayed, this will be the ID of its removal timer
            settingWasUpdatedTimer: null,
            files: []
        };
    }

    onStartSelectingFileUpload = (cloud, name) => {
        // Chama a função passada pelas props
        this.props.onStartSelectingFileUploadChangeFileCloud(cloud, name);
    };

    componentWillUnmount() {
        if (this.state.settingWasUpdatedTimer) {
            clearTimeout(this.state.settingWasUpdatedTimer);
        }
    }
    handleCancel() {
        this.props.onRequestClose();
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }
    handleOptInOutChanged(e) {
        if (e.target.value === 'true') {
            if (this.props.onOptIn) {
                this.props.onOptIn();
                this.handleSettingWasUpdated();
            }
        } else if (e.target.value === 'false') {
            if (this.props.onOptOut) {
                this.props.onOptOut();
                this.handleSettingWasUpdated();
            }
        }
    }
    handleSettingWasUpdated() {
        if (this.state.settingWasUpdatedTimer) {
            clearTimeout(this.state.settingWasUpdatedTimer);
        }
        const newTimer = setTimeout(
            () => this.handleSettingWasUpdatedTimeout(newTimer),
            SETTING_WAS_UPDATED_DURATION_MS
        );
        this.setState({
            settingWasUpdatedTimer: newTimer
        });
    }
    handleSettingWasUpdatedTimeout(thisTimer) {
        if (thisTimer !== this.state.settingWasUpdatedTimer) {
            // some other timer has taken over
            return;
        }
        this.setState({
            settingWasUpdatedTimer: null
        });
    }


    componentDidMount() {
        // Carregue os arquivos quando o componente for montado
        this.loadFiles();
    }

    loadFiles() {
        try {
            const response = axios.get(config.apiUrl + '/files?folder=' + config.userId);
            response.then(x => {
                this.setState({ files: x.data });
            });
        } catch (error) {
            console.error('Falha ao carregar arquivos:', error);
        }

    }

    arrayBufferToBlob = (arrayBuffer, mimeType) => {
        // Cria um Blob a partir do ArrayBuffer e do tipo MIME
        return new Blob([arrayBuffer], { type: mimeType });
    };


    handleFileClick(file) {
        const body = {
            folder: config.userId,
            filename: file
        };

        axios.post(`${config.apiUrl}/read-file`, body, {
            responseType: 'arraybuffer'
        })
            .then(response => {
                const blob = this.arrayBufferToBlob(response.data, 'application/octet-stream'); // Ajuste o tipo MIME conforme necessário
                this.onStartSelectingFileUpload(blob, file);
            })
            .catch(error => {
                console.error('Falha ao ler o arquivo:', error);
            });
    }

    handleDeleteClick(fileId) {
        const body = {
            folder: config.userId,
            filename: fileId
        };
        if (window.confirm('Tem certeza que deseja excluir este arquivo?')) {
            try {
                // Solicita exclusão do arquivo via POST
                axios.post(`${config.apiUrl}/delete-file`, body).then(x => {
                    // Atualiza a lista de arquivos
                    this.setState(prevState => ({
                        files: prevState.files.filter(file => file.id !== fileId),
                        fileIdToDelete: null
                    }));
                    this.loadFiles();
                });

            } catch (error) {
                console.error('Falha ao excluir o arquivo:', error);
            }
        }
    }


    render() {
        const isUndecided = (typeof this.props.isTelemetryEnabled !== 'boolean');
        const isOff = (this.props.isTelemetryEnabled === false);
        const isOn = (this.props.isTelemetryEnabled === true);
        const settingWasUpdated = this.state.settingWasUpdatedTimer && (
            <FormattedMessage {...messages.settingWasUpdated} />
        );
        return (<ReactModal
            isOpen
            className={styles.modalContent}
            contentLabel={this.props.intl.formatMessage(messages.label)}
            overlayClassName={styles.modalOverlay}
            onRequestClose={this.handleCancel}
        >
            <div dir={this.props.isRtl ? 'rtl' : 'ltr'} >
                <Box className={styles.illustration} />
                <Box className={styles.body}>
                    <p>Selecione o projeto que deseja abrir</p>
                    <ul className={styles.fileList}>
                        {this.state.files.map(file => (
                            <li
                                key={file}
                                className={styles.fileItem}
                                onClick={() => this.handleFileClick(file)}
                            >
                                <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    className={styles.fileItemIcon}
                                    onClick={(e) => {
                                        e.stopPropagation(); // Evita que o clique no ícone de lixeira acione o clique do item
                                        this.handleDeleteClick(file);
                                    }}
                                />
                                {file}
                            </li>
                        ))}
                    </ul>
                    <Box className={styles.buttonRow}>
                        <span
                            className={styles.settingWasUpdated}
                            key={this.state.settingWasUpdatedTimer} // restart CSS fade when timer changes
                        >{settingWasUpdated}</span>
                        <button
                            className={styles.optIn}
                            onClick={this.props.onRequestClose}
                        >
                            Novo Projeto
                        </button>
                    </Box>
                </Box>
            </div>
        </ReactModal>);
    }

}
/*
const mapDispatchToProps = dispatch => ({
    onFileChange: (event) => dispatch(handleChange(event))
});

export default connect(null, mapDispatchToProps)(injectIntl(FileModal));*/


export default SBFileUploaderHOC(injectIntl(FileModal));
