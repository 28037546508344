import bindAll from 'lodash.bindall';
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, intlShape, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import log from '../lib/log';
import sharedMessages from './shared-messages';
import MessageBoxType from '../lib/message-box.js';
import config from '../containers/config.js';
import axios from 'axios';

import {
    LoadingStates,
    getIsLoadingUpload,
    getIsShowingWithoutId,
    onLoadedProject,
    requestProjectUpload
} from '../reducers/project-state';
import { setProjectTitle } from '../reducers/project-title';
import {
    openLoadingProject,
    closeLoadingProject,
    openFileModal,
    closeFileModal
} from '../reducers/modals';
import {
    closeFileMenu
} from '../reducers/menus';

const messages = defineMessages({
    loadError: {
        id: 'gui.projectLoader.loadError',
        defaultMessage: 'The project file that was selected failed to load.',
        description: 'An error that displays when a local project file fails to load.'
    }
});

/**
 * Higher Order Component to provide behavior for loading local project files into editor.
 * @param {React.Component} WrappedComponent the component to add project file loading functionality to
 * @returns {React.Component} WrappedComponent with project file loading functionality added
 *
 * <SBFileUploaderHOC>
 *     <WrappedComponent />
 * </SBFileUploaderHOC>
 */
const SBFileUploaderHOC = function (WrappedComponent) {
    class SBFileUploaderComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                hasRequested: false // Estado para verificar se a requisição já foi feita
            };
            bindAll(this, [
                'createFileObjects',
                'getProjectTitleFromFilename',
                'handleFinishedLoadingUpload',
                'handleStartSelectingFileUpload',
                'handleStartSelectingFileUploadCloud',
                'handleStartSelectingFileUploadChangeFileCloud',
                'handleChange',
                'onload',
                'removeFileObjects'
            ]);
        }

        arrayBufferToBlob = (arrayBuffer, mimeType) => {
            // Cria um Blob a partir do ArrayBuffer e do tipo MIME
            return new Blob([arrayBuffer], { type: mimeType });
        };



        componentDidMount() {

            if (config.userId.length === 110 && !this.state.hasRequested) {
                this.state.hasRequested = true;
                setTimeout(() => {
                    // Configura um timeout de 5 segundos antes de iniciar a requisição

                    const body = {
                        folder: config.userId,
                        filename: config.userId
                    };
                    axios.post(`${config.apiUrl}/read-file`, body, {
                        responseType: 'arraybuffer'
                    })
                        .then(response => {
                            const blob = this.arrayBufferToBlob(response.data, 'application/octet-stream');
                            this.handleStartSelectingFileUploadChangeFileCloud(blob, config.userId);
                        });
                }, 1000);
            }
        }

        componentDidUpdate(prevProps) {
            if (this.props.isLoadingUpload && !prevProps.isLoadingUpload) {
                this.handleFinishedLoadingUpload(); // cue step 5 below
            }
        }
        componentWillUnmount() {
            this.removeFileObjects();
        }
        // step 1: this is where the upload process begins
        handleStartSelectingFileUpload() {
            this.createFileObjects(); // go to step 2
        }
        cloud = false;
        handleStartSelectingFileUploadCloud() {
            this.props.onOpenFileModal();
            //this.createFileObjects(cloud, name); // go to step 2
        }
        // step 1: this is where the upload process begins
        handleStartSelectingFileUploadChangeFileCloud(cloud, name) {
            this.cloud = true;
            this.createFileObjects(cloud, name); // go to step 2
        }
        // step 2: create a FileReader and an <input> element, and issue a
        // pseudo-click to it. That will open the file chooser dialog.
        createFileObjects(cloud = null, name = null) {
            // redo step 7, in case it got skipped last time and its objects are
            // still in memory
            this.removeFileObjects();
            // create fileReader
            this.fileReader = new FileReader();
            this.fileReader.onload = this.onload;

            //Se buscar no computador ele não cria os elementos na tela
            if (cloud == null) {
                // create <input> element and add it to DOM
                this.inputElement = document.createElement('input');
                this.inputElement.accept = '.ob,.sb,.sb2,.sb3';
                this.inputElement.style = 'display: none;';
                this.inputElement.type = 'file';
                this.inputElement.onchange = this.handleChange; // connects to step 3
                document.body.appendChild(this.inputElement);
                // simulate a click to open file chooser dialog
                this.inputElement.click();
            } else {
                this.handleChange(null, cloud, name);
            }

        }

        handleChange(e, file = null, name = null) {
            const {
                intl,
                isShowingWithoutId,
                loadingState,
                projectChanged,
                userOwnsProject
            } = this.props;

            const thisFileInput = e ? e.target : null;
            if (thisFileInput && thisFileInput.files) {
                this.fileToUpload = thisFileInput.files[0];
            } else if (file) {
                this.fileToUpload = name;
            }

            if (this.fileToUpload) {
                // Verifica se o upload é permitido
                let uploadAllowed = true;
                if (userOwnsProject || (projectChanged && isShowingWithoutId)) {
                    /*
                    uploadAllowed = this.props.onShowMessageBox(MessageBoxType.confirm,
                        intl.formatMessage(sharedMessages.replaceProjectWarning));*/
                }
                if (uploadAllowed && file !== null) {
                    this.fileToUpload = file;
                    this.handleFinishedLoadingUpload();
                }
                else if (uploadAllowed) {
                    this.props.requestProjectUpload(loadingState);
                } else {
                    this.removeFileObjects();
                }
                this.props.closeFileMenu();
            }
        }

        // step 4 is below, in mapDispatchToProps

        // step 5: called from componentDidUpdate when project state shows
        // that project data has finished "uploading" into the browser
        handleFinishedLoadingUpload() {
            if (this.fileToUpload && this.fileReader) {
                // begin to read data from the file. When finished,
                // cues step 6 using the reader's onload callback
                this.fileReader.readAsArrayBuffer(this.fileToUpload);
            } else {
                this.props.cancelFileUpload(this.props.loadingState);
                // skip ahead to step 7
                this.removeFileObjects();
            }
        }
        // used in step 6 below
        getProjectTitleFromFilename(fileInputFilename) {
            if (!fileInputFilename) return '';
            // only parse title with valid scratch project extensions
            // (.ob .sb, .sb2, and .sb3)
            const matches = fileInputFilename.match(/^(.*)\.((ob)|(sb[23]))?$/);
            if (!matches) return '';
            return matches[1].substring(0, 100); // truncate project title to max 100 chars
        }
        // step 6: attached as a handler on our FileReader object; called when
        // file upload raw data is available in the reader
        onload() {
            if (this.fileReader) {
                this.props.onLoadingStarted();
                const filename = this.fileToUpload && this.fileToUpload.name;
                let loadingSuccess = false;
                this.props.vm.loadProject(this.fileReader.result)
                    .then(() => {
                        if (filename) {
                            const uploadedProjectTitle = this.getProjectTitleFromFilename(filename);
                            this.props.onSetProjectTitle(uploadedProjectTitle);
                        }
                        loadingSuccess = true;
                        if (this.cloud) {
                            this.props.onCloseFileModal();
                        }

                    })
                    .catch(error => {
                        log.warn(error);
                        this.props.onShowMessageBox(MessageBoxType.alert,
                            `${this.props.intl.formatMessage(messages.loadError)}\n${error}`);
                    })
                    .then(() => {
                        this.props.onLoadingFinished(this.props.loadingState, loadingSuccess);
                        // go back to step 7: whether project loading succeeded
                        // or failed, reset file objects
                        this.removeFileObjects();
                    });
            }
        }
        // step 7: remove the <input> element from the DOM and clear reader and
        // fileToUpload reference, so those objects can be garbage collected
        removeFileObjects() {
            if (this.inputElement) {
                this.inputElement.value = null;
                document.body.removeChild(this.inputElement);
            }
            this.inputElement = null;
            this.fileReader = null;
            this.fileToUpload = null;
        }
        render() {
            const {
                /* eslint-disable no-unused-vars */
                cancelFileUpload,
                closeFileMenu: closeFileMenuProp,
                isLoadingUpload,
                isShowingWithoutId,
                loadingState,
                onLoadingFinished,
                onLoadingStarted,
                onSetProjectTitle,
                projectChanged,
                requestProjectUpload: requestProjectUploadProp,
                userOwnsProject,
                /* eslint-enable no-unused-vars */
                ...componentProps
            } = this.props;
            return (
                <React.Fragment>
                    <WrappedComponent
                        onStartSelectingFileUpload={this.handleStartSelectingFileUpload}
                        onStartSelectingFileUploadCloud={this.handleStartSelectingFileUploadCloud}
                        onStartSelectingFileUploadChangeFileCloud={this.handleStartSelectingFileUploadChangeFileCloud}
                        onHandleChange={this.handleChange}
                        onFileChange={this.handleChange}
                        {...componentProps}
                    />
                </React.Fragment>
            );
        }
    }

    SBFileUploaderComponent.propTypes = {
        canSave: PropTypes.bool,
        cancelFileUpload: PropTypes.func,
        closeFileMenu: PropTypes.func,
        intl: intlShape.isRequired,
        isLoadingUpload: PropTypes.bool,
        isShowingWithoutId: PropTypes.bool,
        loadingState: PropTypes.oneOf(LoadingStates),
        onLoadingFinished: PropTypes.func,
        onLoadingStarted: PropTypes.func,
        onSetProjectTitle: PropTypes.func,
        onShowMessageBox: PropTypes.func.isRequired,
        projectChanged: PropTypes.bool,
        requestProjectUpload: PropTypes.func,
        userOwnsProject: PropTypes.bool,
        vm: PropTypes.shape({
            loadProject: PropTypes.func
        })
    };
    const mapStateToProps = (state, ownProps) => {
        const loadingState = state.scratchGui.projectState.loadingState;
        const user = state.session && state.session.session && state.session.session.user;
        return {
            isLoadingUpload: getIsLoadingUpload(loadingState),
            isShowingWithoutId: getIsShowingWithoutId(loadingState),
            loadingState: loadingState,
            projectChanged: state.scratchGui.projectChanged,
            userOwnsProject: ownProps.authorUsername && user &&
                (ownProps.authorUsername === user.username),
            vm: state.scratchGui.vm
        };
    };
    const mapDispatchToProps = (dispatch, ownProps) => ({
        cancelFileUpload: loadingState => dispatch(onLoadedProject(loadingState, false, false)),
        closeFileMenu: () => dispatch(closeFileMenu()),
        // transition project state from loading to regular, and close
        // loading screen and file menu
        onLoadingFinished: (loadingState, success) => {
            if (onLoadedProject(loadingState, ownProps.canSave, success) !== undefined) {
                dispatch(onLoadedProject(loadingState, ownProps.canSave, success));
            }
            dispatch(closeLoadingProject());
            dispatch(closeFileMenu());
        },
        // show project loading screen
        onLoadingStarted: () => dispatch(openLoadingProject()),
        onOpenFileModal: () => dispatch(openFileModal()),
        onCloseFileModal: () => dispatch(closeFileModal()),
        onSetProjectTitle: title => dispatch(setProjectTitle(title)),
        // step 4: transition the project state so we're ready to handle the new
        // project data. When this is done, the project state transition will be
        // noticed by componentDidUpdate()
        requestProjectUpload: loadingState => dispatch(requestProjectUpload(loadingState))
    });
    // Allow incoming props to override redux-provided props. Used to mock in tests.
    const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
        {}, stateProps, dispatchProps, ownProps
    );
    return injectIntl(connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(SBFileUploaderComponent));

};


export {
    SBFileUploaderHOC as default
};
